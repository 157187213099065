import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Input } from "antd";
import Text from "components/atoms/Text";

const Wrapper = styled.div`
  padding: 0;
  position: absolute;
  top: 0;
  left: 0;
  transform: ${(props) =>
    props.step === 1
      ? "none"
      : props.previousStep > props.step || props.previousStep === props.step
      ? "translateX(100%)"
      : "translateX(-100%)"};
  opacity: ${(props) => (props.step === 1 ? 1 : 0)};
  visibility: ${(props) => (props.step === 1 ? "visible" : "hidden")};
  transition: 600ms;
  // position: static;
`;
const personalDomainList = [
  "gmail",
  "yahoo",
  "icloud",
  "hotmail",
  "live",
  "me",
  "mindspring",
  "comcast",
  "msn",
  "aol",
  "outlook",
  "ymail",
];

const CurrentEmployee = ({ step, previousStep, group, groups, groupObj, setEmail, setCanSendEmail }) => {
  const [validEmail, setValidEmail] = useState(false);
  const [validDomain, setValidDomain] = useState(false);
  const [validQuinnipiacDomain, setValidQuinnipiacDomain] = useState(false);

  const [domainMap, setDomainMap] = useState({});

  useEffect(() => {
    const newDomainMap = {};
    for (const group of groups) {
      newDomainMap[group.name] = group.otherDomains;
    }
    setDomainMap(newDomainMap);
  }, [groups]);

  if (validEmail && validDomain) {
    if (group === "quinnipiac" && !validQuinnipiacDomain) {
      setCanSendEmail(false);
    } else {
      setCanSendEmail(true);
    }
  } else {
    setCanSendEmail(false);
  }

  const validateEmail = (email) => {
    const emailRegex =
      /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/;

    setValidEmail(emailRegex.test(email));
    if (group === "quinnipiac") {
      const quinnipiacRegex =
        /^[a-z0-9!#$%&'*+\/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+\/=?^_`{|}~-]+)*\.[a-z0-9!#$%&'*+\/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+\/=?^_`{|}~-]+)*@quinnipiac\.edu/;
      const quRegex =
        /^[a-z0-9!#$%&'*+\/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+\/=?^_`{|}~-]+)*\.[a-z0-9!#$%&'*+\/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+\/=?^_`{|}~-]+)*@qu\.edu/;
      setValidQuinnipiacDomain(quinnipiacRegex.test(email) || quRegex.test(email));
    }
    const domain = email.split("@")[1];
    if (groupObj?.onlyAllowListedDomains) {
      if ((domainMap[group] && domainMap[group].includes(domain)) || email === "sarah.michalczuk@gmail.com") {
        setValidDomain(true);
      } else {
        setValidDomain(false);
      }
    } else if (groupObj?.excludePersonalDomains) {
      if (domain && personalDomainList.some((personalDomain) => domain.includes(personalDomain))) {
        setValidDomain(false);
      } else {
        setValidDomain(true);
      }
    } else {
      setValidDomain(true);
    }
  };

  return (
    <div style={{ position: "relative" }}>
      <Wrapper step={step} previousStep={previousStep}>
        <Text
          sectionTitle
          left
          style={{ marginBottom: 0 }}
        >{`Please enter your work email address to see your plans.`}</Text>
        {groupObj.requiresEmailVerification ? (
          <Text italic left tiny style={{ marginTop: "10px" }}>
            We'll only email you here once to verify your identity, but we'll never email you at work to ask about your
            healthcare.
          </Text>
        ) : (
          <Text italic left tiny style={{ marginTop: "10px" }}>
            We'll never email you at work to ask about your healthcare.
          </Text>
        )}
        <Input
          type="email"
          style={{ width: "100%", maxWidth: "500px" }}
          status={validEmail ? (validDomain ? "" : "error") : ""}
          placeholder="Enter work email"
          onChange={(e) => {
            const email = e.target.value?.toLowerCase();
            setEmail(email);
            validateEmail(email);
          }}
        />
        {validEmail && !validDomain && (
          <Text tiny left red>
            This domain will not give you access to these plans. If you believe this is an error, email{" "}
            <a href={"mailto:info@predictabill.com"}>info@predictabill.com</a>.
          </Text>
        )}
        {validEmail && validDomain && group === "quinnipiac" && !validQuinnipiacDomain && (
          <Text tiny left red>
            Please enter the version of your email address in the format FirstName.LastName@quinnipiac.edu. If you do
            not have a valid email address in this format, please email{" "}
            <a href={"mailto:info@predictabill.com"}>info@predictabill.com</a>.
          </Text>
        )}
      </Wrapper>
    </div>
  );
};

export default CurrentEmployee;
